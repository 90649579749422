//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TheFooter'
}
