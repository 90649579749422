//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheFooter from "@/containers/TheFooter";

export default {
	name: 'Main',
	components: {
		TheFooter
	},
	data() {
		return {
			show: false
		}
	},
  mounted() {
    this.$store.state.loading = false
    const role = this.$session.get('role') ? this.$session.get('role') : null
    if (role >= 1)
      this.$router.replace('/dashboard')
    else if (role === 0)
      this.$router.replace('/user/contracts')
    else
      this.$router.push('/')
  },
}
